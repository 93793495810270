.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  padding: 0rem 1rem;
}

.content {
  text-align: center;
  z-index: 1;
}

.title {
  font-family: "Gloock", serif;
  font-weight: 400;
  font-size: 2.5rem;
}

.subtitle {
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.description {
  margin-top: 1.5rem;
  font-size: 1rem;
}

.cta-button {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  background-color: #29bab9;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
